.AddCoach {
  &__button {
    margin-top: 1em;
  }
}

.focusareaImg {
  max-height: 80px;
  width: 80px;
}

