.asd {
    font-size: 5rem;
    text-transform: uppercase;
    font-weight: 700;
    background-image: linear-gradient(to right, #04148E, #0C67CD, #0391B0, #00BBE3);
    -webkit-background-clip: text;
    background-clip: text; /* Standard property */
    color: transparent;
    letter-spacing: .2rem;
    transition: all .2s;
    margin: 1em auto;
}

.asd:hover {
    transform: skewY(2deg) skewX(15deg) scale(1.1);
    text-shadow: .5rem 1rem 2rem rgba(0, 0, 0, .2);
}
