.Searchbar {
  display: flex;
  align-items: center;
  height: 1em !important;

  &__icon {
    height: 100% !important;
    font-size: 0.85em !important;
    margin-left: 0.2em;
  }

  &__input {
    height: 1.2em !important;
    padding: 1.2em !important;
    padding-left: 2em !important;
  }
}
