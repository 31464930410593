.NewTable {
    font-family: sans-serif;
    border-radius: 10px;
    
    tr:nth-child(2n) td {
        background-color: #f9fafb;
    }

    thead > tr > th {
        border: none;
        font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 700;
        color: #4B5563;
        background-color: #e4e6eb;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 24px;
        text-decoration: none solid rgb(75,85,99);
    }

    td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    td:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

}