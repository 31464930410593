.Add {
  &__button {
    margin-top: 1em;
  }
}


@media only screen and (max-width: 768px) {
  .Add {
      display: flex;
      flex-direction: column;
  }
}

.checkedfocusarea {
  width: 50px !important;
}


.editorClassName {
  border: 0.5px solid #ccc;
}