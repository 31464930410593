.AddCoacheeExcel {
  &__Form {
    display: flex;
    justify-content: space-around;
    width: 100%;

    &__item {
      width: 30%;
    }
  }

  &__Button {
    margin-top: 2rem;
  }

  &__info {
    &_error {
      color: #f94015;
    }

    &_success {
      color: #55e592;
    }
  }
}
