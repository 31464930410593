@import './index.scss';

html {
  box-sizing: border-box;
  font-size: 62.5%;
  width: 100%;
  height: 100% !important;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
a {
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: bold !important;
  color: black !important;
}

span.emoji {
  font-size: 30em;
}

body,
#root {
  height: 100% !important;
}

body {
  font-size: 1.6rem;
  line-height: 2;
  font-family: 'Helvetica Neue', sans-serif !important;
  margin: 0;
  background-color: $background-primary;
  background-repeat: repeat;
}

h1 {
  font-size: 2.5rem !important;
}
h2 {
  font-size: 2.2rem !important;
}
h3 {
  font-size: 1.9rem !important;
}
h4 {
  font-size: 1.6rem !important;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.chakra-form__label {
  font-size: 0.8em !important;
}

.chakra-modal {
  &__content-container {
    align-items: center !important;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__content {
    padding: 0.5em;
    border-radius: 0.8em !important;
    min-width: 30vw !important;
  }
}

.title_table {
  display: flex;
  justify-content: center;
}

.ant-avatar>img {
  object-fit: contain !important;
}

.avatar-img {
  width: 150px !important;
  height: 150px !important;
}

.dragger-img {
  margin-bottom: 10px !important;
  padding: 0 30px !important;
}

.areafocusadd {
  width: 80px;
  height: 80px;
}

.Card {
  text-align: center;
  width: 60%;
  aspect-ratio: 1;
  border-radius: 1.5em;
  background-color: $blue-light;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mr-5 {
  margin-right: 5rem;
}

.pl-5 {
  padding-left: 5rem;
}

.w-100 {
  width: 100% !important;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

button {
  position: initial !important;
}

.chakra-form__error-message {
  color: $error;
}

.chakra-button.Button {
  width: 100%;
  height: 3em;
  border-radius: 0.8em;
  font-size: 0.7em;
  padding: 1em !important;

  &--primary {
    background-color: $new-blue-bonum;
    color: $background-primary;
  }

  &--secondary {
    color: $background-primary;
    background-color: $blue-secondary;
    min-width: initial;
    height: 2em;
    padding: 0 2em;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.AddCoach__input {
  font-size: 20px !important;
}

.form-crud {
  width: 95%;
}

.Content {
  border-radius: 1em;
  background-color: $background-primary;
  padding: 1em;
  height: inherit;
  box-shadow: $box-shadow-content;
  margin-right: 1em;
  margin-top: 1em;
}

input.chakra-input {
  background-color: $background-primary;
  border-radius: 1rem;
  height: 3em;
  font-size: 0.8em;
  width: 100%;
  padding: 1.5em 1em;

  &::placeholder {
    color: $gray;
  }
}

select.chakra-select {
  background-color: $background-primary;
  border-radius: 3rem;
  height: 3em;
  font-size: 0.7em;
  width: 100%;
  box-shadow: 4px 4px 6px 1px rgba(0, 0, 0, 0.1) !important;
}

.container {
  max-width: 136.8rem;
  margin: 0 auto;
  width: 95%;

  &::-webkit-scrollbar {
    display: none;
  }

  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.full-height {
    height: calc(100% - 25vh);
  }
}

.chakra-tabs {
  &__tab {
    font-size: 1em !important;
  }
}

.icon-button {
  aspect-ratio: 1;
  margin-left: 1em;

  > * {
    height: 1.5em !important;
    width: 1.5em !important;
  }

  &--primary {
    background-color: $new-blue-bonum !important;
    color: $background-primary;
  }
}

@media (max-width: 468px) {
  .container {
    max-width: 114rem;
    margin: 0 auto;
    width: 85%;
  }
}

img {
  max-width: 100%;
}

.background {
  background-color: rgba(248, 248, 248);
  box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  margin: 1.5em auto !important;
  margin-bottom: inherit;
  padding: 2em;

  @media (max-width: 912px) {
    padding-left: 1em;
    padding-right: 1em;
  }
}