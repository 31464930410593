.AddCoachee {
  &__button {
    margin-top: 1em;
  }
  &__input {
    &_p{
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .AddCoachee {
    display: flex;
    flex-direction: column;
  }
}