$background-primary: #ffffff;
$background-secondary: rgb(242, 243, 247);
$blue-light: rgb(228, 239, 248, 0.92);
$blue-primary: #1e2843;
//$blue-bonum: #5163ad;
$blue-secondary: #173969;
$blue-bonum: #299eff;
$blue-bonum-transparent: #299fff29;
$new-blue-bonum: #299eff;
$gray: #707070;
$gray-light: #a9a9aa;
$gray-100: #fafafae8;
$gray-200: #e5e2e2;
$gray-300: #a1a6b3;
$spinner: rgb(61, 109, 235);
$yellow: #f4e31f;
$green: #55e592;
$font: black;
$complementary: #ff9e19;
$steps: rgba(248, 248, 248);
$error: #f94015 !important;
