.Reports {
  &__title {
    text-align: center;
  }

  &__subtitle {
    text-align: center;
    margin-bottom: 2rem;
  }

  &__email_form {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
    display: flex;

    &_input {
      max-width: 30rem;
      margin-right: 2rem !important;
    }
  }
}
