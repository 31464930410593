.Sessions {
  height: $content-height;  
  &_content {
    
  }
}

// @import './components/AddCoachee/AddCoachee.scss';


.Content_Sessions {
  border-radius: 1em;
  background-color: $background-primary;
  padding: 1em;
  box-shadow: $box-shadow-content;
  margin-right: 1em;
  margin-top: 1em;
}