.dynamic-delete-button {
  position: relative;
  top: -110px;
  left: 50vw;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}

.CreateQuestions {
  &__question {
    margin-bottom: 2rem !important;
  }
}
@import "./components/CreateEvaluation/CreateEvaluation.scss";
