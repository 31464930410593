.Header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1em;
  padding-bottom: 0;

  &__info {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &_notification {
      width: 1.5em;
      height: 1.5em;
      margin-right: 1.5em;
    }
  }
}
