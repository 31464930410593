.Auth {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  place-items: center;

  &__welcome {
    display: flex;
    flex-direction: column;
    padding: 2em;
    justify-content: flex-end;
    // background: url('../../assets/images/login.png');
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(23,57,105,1) 35%, rgba(0,212,255,1) 100%);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;

    &_title,
    &_subtitle {
      font-size: 3em !important;
      color: $background-primary !important;
    }

    &_subtitle {
      color: $complementary !important;
    }
  }

  &__actions {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 820px) {
    display: block;

    &__welcome {
      height: 35vh;
    }

    &__actions {
      height: 50vh;
    }
  }
}

@import './components/Login/Login.scss';
