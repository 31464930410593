.TabSession {
  &__button {
    margin-top: 1em;
  }
}

@media only screen and (max-width: 768px) {
  .TabSession {
    display: flex;
    flex-direction: column;
  }
}