@tailwind base;
@tailwind components;
@tailwind utilities;

/**************************** Colors ****************************/
@import "./colors.scss";

/*********************** Variables *********************/
@import "./variables.scss";

/*********************** Layouts *******************/
@import "layouts/dashboard/dashboard.scss";

/********************** Components *******************/
@import "components/Header/Header.scss";

@import "components/Sidebar/Sidebar.scss";

@import "components/Searchbar/Searchbar.scss";

/*********************** Pages ***********************/
@import "pages/Auth/Auth.scss";
@import "pages/Home/Home.scss";
@import "pages/Coachees/Coachees.scss";
@import "pages/Coaches/Coaches.scss";
@import "pages/Sessions/Sessions.scss";
@import "pages/Reports/Reports.scss";
@import "pages/MentoringEvaluations/MentoringEvaluations.scss";
