.ant-table table {
  font-size: 12px;
}

.Coachees {
  &__rangepicker {
    margin-bottom: 2rem;
    p {
      color: #707070;
    }
  }
}

@import "./components/AddCoachee/AddCoachee.scss";
@import "./components/TabCoachee/TabCoachee.scss";
@import "./components/TabSession/TabSession.scss";
@import "./components/AddCoacheeExcel/AddCoacheeExcel.scss";
